import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  capitalize,
} from "@mui/material";
import { func, string } from "prop-types";
import { getEngagementVsReachData, getEngagementVsReachWeeklyData, getFilter } from "../../../../../../services/advanceAnalytics";
import { notify } from "../../../../../../redux/features/system/systemAlert";
import { Spinner } from "react-bootstrap";
import StatsCard from "../common/StatsCard";
import { convertToInternationalNumberSystem } from "../../../../../../utility/stringOperations";
import { logout } from "../../../../../../redux/features/user/userSlice";
import { StyledTableCell, StyledTableRow, StyledTableRowPlain } from "../common/tableStyles";
import CheckboxDropdownFilter from "../common/CheckboxDropdownFilter";
import { PlatformIdMapping } from "../../../../../../utility/plaformIcons";
import { getFormattedDate } from "../../../../../../utility/momentManipulations";
import MyPaper from "../../../../../../utility/oryxUtils/MyPaper";
import config from "../../../../../../config/main.json"

const svg_icon = `${config.BASE_IMAGE_URL}Tasks.svg`


const tableBody = (rows, isWeeklyTable) => {
  return (
    <TableContainer
      component={Paper}
      elevation={5}
      sx={{ width: "100%", maxHeight: isWeeklyTable ? 300 : "100%", borderRadius: 1 }}
    >
      <Table size="small" aria-label="customized table" stickyHeader>
        <TableHead sx={{ borderRadius: 0 }}>
          <TableRow>
            {Object.keys(rows[0])?.map((rowKey, rowKeyIdx) =>
              rowKeyIdx === 0 ? (
                <StyledTableCell>{rowKey}</StyledTableCell>
              ) : (
                <StyledTableCell align={isWeeklyTable ? "center" : "right"}>{rowKey}</StyledTableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, idx) => {
            const containsTotal = Object.values(row).includes("Total");
            return containsTotal ? (
              <StyledTableRow key={idx}>
                {Object.keys(row)?.map((rowKey, rowKeyIdx) => {
                  return rowKeyIdx === 0 ? (
                    <StyledTableCell component="th" scope="row">
                      {row[rowKey]}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align={isWeeklyTable ? "center" : "right"}>{row[rowKey]}</StyledTableCell>
                  );
                })}
              </StyledTableRow>
            ) : (
              <StyledTableRowPlain key={idx}>
                {Object.keys(row)?.map((rowKey, rowKeyIdx) => {
                  return rowKeyIdx === 0 ? (
                    <StyledTableCell component="th" scope="row">
                      {row[rowKey]}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align={isWeeklyTable ? "center" : "right"}>{row[rowKey]}</StyledTableCell>
                  );
                })}
              </StyledTableRowPlain>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const NoDataText = () => {
  return <Typography>No data available</Typography>;
};

// Main component
const EngagementReach = (props) => {
  const { user, dispatch, campaignId, getLastRefreshedTime, getNextRefreshTime } = props;

  const [analyticsData, setAnalyticsData] = useState(null);
  const [weeklyData, setWeeklyData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [locationFilter, setLocationFilter] = useState([]);
  const [languageFilter, setLanguageFilter] = useState([]);
  const [genderFilter, setGenderFilter] = useState([]);
  const [weekFilter, setWeekFilter] = useState([]);
  const [checked, setChecked] = useState(false);
  const [generationIssue, setGenerationIssue] = useState("noFilters");
  const [selectedFilters, setSelectedFilters] = useState({
    weeks: [],
    gender: [],
    location: [],
    language: [],
  });

  const getFilterData = async () => {
    //location Filter
    const locationData = await getFilter("getLocations", user, campaignId);
    if (locationData) {
      const data = await locationData.json();
      setLocationFilter(data === null ? [] : data);
    }

    //language Filter
    const languageData = await getFilter("getLanguage", user, campaignId);
    if (languageData) {
      const data = await languageData.json();
      if (data !== null) setLanguageFilter(data === null ? [] : data);
    }

    // gender Filter
    const genderData = await getFilter("getGender", user, campaignId);
    if (genderData) {
      const data = await genderData.json();
      setGenderFilter(data === null ? [] : data);
    }

    // week Filter
    const weekData = await getFilter("getWeeks", user, campaignId);
    if (weekData) {
      const data = await weekData.json();
      const value = data === null ? [] : data?.filter((value) => value !== "All");
      setWeekFilter(value);
    }
  };
  // fetch data for a specific campaign
  const getData = async (filters, filterWeeksWithNoPublications = true) => {
    setIsLoading(true);
    try {
      const res = await getEngagementVsReachData(campaignId, filters, user, dispatch);

      if (res.ok) {
        const data = await res.json();
        const transformAgeData = () => {
          const keys = Object.keys(data.AgeRangeSplit[0]);
          keys?.map((key) => {
            if (key !== "Total") {
              const transformedKey = `${key} Years`;
              data.AgeRangeSplit[0][transformedKey] = data.AgeRangeSplit[0][key];
              delete data.AgeRangeSplit[0][key];
            }
          });
        };
        if (data.PlatformSplit) {
          const platforms = Object.keys(PlatformIdMapping);
          const transformPlatformData = () => {
            const keys = Object.keys(data.PlatformSplit[0]);
            keys?.map((key) => {
              if (!platforms.includes(key.toLowerCase()) && key !== "Total") {
                const transformedKey = "Incorrect Post URL";
                data.PlatformSplit[0][transformedKey] = data.PlatformSplit[0][key];
                delete data.PlatformSplit[0][key];
              }
            });
          };
          transformPlatformData();
        }

        // transformAgeData();
        if (data.TotalInfluencers === 0 && filters.weeks?.length > 0) {
          setGenerationIssue("filtersWeek");
        }
        setAnalyticsData(data);
        getLastRefreshedTime(data.LastRefreshedTime);
        getNextRefreshTime(data.NextRefreshTime);
      }

      const weeklyDataRes = await getEngagementVsReachWeeklyData(campaignId, filters, user, dispatch);
      if (weeklyDataRes.ok) {
        const data = await weeklyDataRes.json();
        data["FilteredWeekSplit"] = JSON.parse(JSON.stringify(data["WeekSplit"]));

        if (filterWeeksWithNoPublications) {
          const keys = data["WeekSplit"]?.map((obj) => Object.keys(obj)[0]);
          data["FilteredWeekSplit"] = data["WeekSplit"]?.filter((value, index) => {
            const metric = "engagement"; // Assuming engagement data is needed for filtering
            return value[keys[index]][metric] !== 0;
          });
        }

        setWeeklyData(data);
      }
    } catch (error) {
      setIsLoading(true);
    }
    setIsLoading(false);
  };

  const resetFilters = () => {
    const filters = {
      weeks: [],
      gender: [],
      location: [],
      language: [],
    };
    getData(filters);
    setSelectedFilters(filters);
  };

  //fetch filters & analysis data
  useEffect(() => {
    getFilterData();
    resetFilters();
  }, [campaignId]);

  const filtersChangeHandler = (value, key) => {
    setSelectedFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
      };
      if (value === "All") {
        switch (key) {
          case "weeks":
            newFilters[key] = [];
            break;
          case "location":
            selectedFilters[key]?.length === locationFilter?.length ? (newFilters[key] = []) : (newFilters[key] = locationFilter);
            break;

          case "gender":
            selectedFilters[key]?.length === genderFilter?.length ? (newFilters[key] = []) : (newFilters[key] = genderFilter);
            break;

          case "language":
            selectedFilters[key]?.length === languageFilter?.length ? (newFilters[key] = []) : (newFilters[key] = languageFilter);
            break;

          default:
            break;
        }
      } else {
        if (key === "weeks") {
          newFilters[key] = [];
          newFilters[key].push(value["weekNo"]);
        } else {
          if (prevFilters[key].includes(value)) {
            newFilters[key] = newFilters[key]?.filter((item) => item !== value);
          } else {
            newFilters[key].push(value);
          }
        }
      }
      getData(newFilters, !checked);
      return newFilters;
    });
  };

  // create table from split data
  const createGraphTable = useCallback(
    (graphKey, intitalCol) => {
      let data = [];
      let filteredData = Object.entries(analyticsData[graphKey][0]);

      Object.entries(analyticsData[graphKey][0]).forEach((item, idx) => {
        if (item[0] === "Total") {
          filteredData.push(filteredData.splice(idx, 1)[0]);
        }
      });

      if (graphKey === "PostTypeSplit") {
        filteredData = filteredData?.filter((key) => key[0] !== "Total");
      }

      filteredData = Object.fromEntries(filteredData);

      if (graphKey) {
        Object.keys(filteredData).forEach((key, idx) => {
          data.push({ [intitalCol]: capitalize(key) });
          data[idx]["No of Influencers"] = convertToInternationalNumberSystem(analyticsData[graphKey][0][key]["NoOfInfluencers"]) || 0;
          data[idx]["No of Posts"] = convertToInternationalNumberSystem(analyticsData[graphKey][0][key]["NoOfPosts"]) || 0;
          data[idx]["Likes"] = convertToInternationalNumberSystem(analyticsData[graphKey][0][key]["Likes"]) || 0;
          data[idx]["Comments"] = convertToInternationalNumberSystem(analyticsData[graphKey][0][key]["Comment"]) || 0;
          data[idx]["Reshares"] = convertToInternationalNumberSystem(analyticsData[graphKey][0][key]["Shares"]) || 0;
          data[idx]["Engagement"] = convertToInternationalNumberSystem(analyticsData[graphKey][0][key]["Engagement"]) || 0;
          data[idx]["Engagement Rate"] = `${analyticsData[graphKey][0][key]["AverageEngagementRate"]
            ? `${analyticsData[graphKey][0][key]["AverageEngagementRate"]}%`
            : 0
            }`;
          data[idx]["Avg Reach/Post"] = convertToInternationalNumberSystem(analyticsData[graphKey][0][key]["AverageReachPerPost"]) || 0;
          data[idx]["Avg Eng/Post"] = convertToInternationalNumberSystem(analyticsData[graphKey][0][key]["AverageEngagementPerPost"]) || 0;
        });
      }
      return data;
    },
    [analyticsData]
  );

  // create table for campaign weeks
  const createWeekGraphTable = (graphKey, intitalCol) => {
    let data = [];
    weeklyData[graphKey].forEach((item, idx) => {
      const weekItem = item[Object.keys(item)[0]];
      data.push({ [intitalCol]: `${weekItem?.weekNo} (${getFormattedDate(weekItem?.["startDate"], "DD MMM YY")} to ${getFormattedDate(weekItem?.["endDate"], "DD MMM YY")})` });
      data[idx]["No of Influencers"] = convertToInternationalNumberSystem(weekItem["influencers"]) || 0;
      data[idx]["No of Posts"] = convertToInternationalNumberSystem(weekItem["posts"]) || 0;
      data[idx]["Likes"] = convertToInternationalNumberSystem(weekItem["likes"]) || 0;
      data[idx]["Comments"] = convertToInternationalNumberSystem(weekItem["comment"]) || 0;
      data[idx]["Reshares"] = convertToInternationalNumberSystem(weekItem["reshares"]) || 0;
      data[idx]["Engagement"] = convertToInternationalNumberSystem(weekItem["engagement"]) || 0;
      data[idx]["Engagement Rate"] = `${weekItem["engRate"] || 0}%`;
      data[idx]["Avg Reach/Post"] = convertToInternationalNumberSystem(weekItem?.averageReachPerPost) || 0;
      data[idx]["Avg Eng/Post"] = convertToInternationalNumberSystem(weekItem?.averageEngagementPerPost) || 0;
    });
    return data;
  };

  const TableWithHeading = ({ heading, tableData, tooltip }) => {
    return (
      <>
        <Typography variant="h5" mb="1rem" sx={{ textTransform: "titlecase", fontWeight: "bold" }}>
          {heading}
        </Typography>
        {tableData}
        <FormHelperText sx={{ paddingTop: "10px" }}>{tooltip}</FormHelperText>
      </>
    );
  };

  const hideRows = (checked) => {
    const keys = weeklyData?.WeekSplit?.map((obj) => Object.keys(obj)[0]);

    // Create a new array with filtered elements
    weeklyData.FilteredWeekSplit = [];
    weeklyData?.WeekSplit.forEach((value, index) => {
      if (checked && value[keys[index]]["engagement"] !== 0) {
        weeklyData.FilteredWeekSplit.push({ [keys[index]]: value[keys[index]] });
      } else if (!checked) {
        weeklyData.FilteredWeekSplit.push({ [keys[index]]: value[keys[index]] });
      }
      return weeklyData;
    });
  };
  const handleChange = () => {
    setChecked(!checked);
    hideRows(checked);
  };

  // const { currentSection, setCurrentSection } = props;
  const renderContentHandler = () => {
    let content = null;
    if (isLoading) {
      content = <Spinner style={{ marginBlock: "1rem" }} />;
    } else if (!isLoading && (!analyticsData || !analyticsData?.AgeRangeSplit)) {
      content = (
        <>
          {generationIssue === "noFilters" && (
            <MyPaper>
              <div style={{ textAlign: "center", width: "30vw", margin: "0 auto" , height: "45vh"}}>
                <img src={svg_icon} style={{ width: '120px', height: '120px' }} alt="Icon" />
                <p style={{ color: "black" }}><b>BI Report is not ready</b></p>
                <p style={{ width: "70%", textAlign: "center", margin: "0 15%" }}>
                  The BI Dashboard is not available either because campaign does not have enough
                  publications or does not meet minimum Campaign Quality Score.
                </p>
              </div>
            </MyPaper>
          )}
          {generationIssue === "filtersWeek" && (
            <div style={{ textAlign: "center", paddingTop: "20%" }}>No data exists for selected week.</div>
          )}
        </>
      );
    } else {
      content = (
        <>
          {" "}
          <Grid container spacing={2} width={"100%"}>
            <Grid item xs={2}>
              <StatsCard
                statText="Total No Of Influencers"
                statNumber={
                  analyticsData?.TotalInfluencers ? convertToInternationalNumberSystem(analyticsData?.TotalInfluencers) : null
                }
                color="#d0da59"
              />
            </Grid>
            <Grid item xs={2}>
              <StatsCard
                statText="Total No Of Posts"
                statNumber={analyticsData?.TotalPosts ? convertToInternationalNumberSystem(analyticsData?.TotalPosts) : null}
                color="#f9d567"
              />
            </Grid>

            <Grid item xs={2}>
              <StatsCard
                statText="Total Engagement"
                statNumber={
                  analyticsData?.TotalEngagement ? convertToInternationalNumberSystem(analyticsData?.TotalEngagement) : null
                }
                color="#d0da59"
              />
            </Grid>
            <Grid item xs={2}>
              <StatsCard
                statText="Engagement Rate"
                statNumber={analyticsData?.ERR && `${analyticsData?.ERR}%`}
                color="#f9d567"
                tooltip="Engagement rate, which shows the percent of the audience who engages with the content posted by this influencer, including shares, likes and comments."
              />
            </Grid>

            <Grid item xs={2}>
              <StatsCard
                statText="Total Reach"
                statNumber={analyticsData?.TotalReach ? convertToInternationalNumberSystem(analyticsData?.TotalReach) : null}
                color="#d0da59"
                tooltip="Reach refers to the number of users who have seen publication(s).
            *Reach is calculated as number of followers where true reach is not available."
              />
            </Grid>

            <Grid item xs={2}>
              <StatsCard
                statText="Average Reach Per Post"
                statNumber={
                  analyticsData?.AverageReachPerPost
                    ? convertToInternationalNumberSystem(analyticsData?.AverageReachPerPost)
                    : null
                }
                color="#f9d567"
              />
            </Grid>
          </Grid>
          <Box mt="1rem" marginX="auto">
            <Grid container width={"100%"} my="1rem" spacing={2}>
              <Grid item xs={12}>
                <TableWithHeading
                  heading={"By Age"}
                  tableData={
                    Object.keys(analyticsData?.AgeRangeSplit[0])?.length > 1 ? (
                      tableBody(createGraphTable("AgeRangeSplit", "Age"))
                    ) : (
                      <NoDataText />
                    )
                  }
                  tooltip="*Only captured for Influencers with Age data available. "
                />
              </Grid>
              <Grid item xs={12}>
                <TableWithHeading
                  heading={"By Gender"}
                  tableData={
                    Object.keys(analyticsData?.GenderSplit[0])?.length > 1 ? (
                      tableBody(createGraphTable("GenderSplit", "Gender"))
                    ) : (
                      <NoDataText />
                    )
                  }
                  tooltip="*Only captured for Influencers with Gender data available."
                />
              </Grid>
            </Grid>
            <Grid container width={"100%"} my="1rem" spacing={2}>
              <Grid item xs={12}>
                <TableWithHeading
                  heading={"By Platform"}
                  tableData={
                    Object.keys(analyticsData?.PlatformSplit[0])?.length > 1 ? (
                      tableBody(createGraphTable("PlatformSplit", "Platform"))
                    ) : (
                      <NoDataText />
                    )
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TableWithHeading
                  heading={"By Post Type"}
                  tableData={
                    Object.keys(analyticsData?.PostTypeSplit[0])?.length > 1 ? (
                      tableBody(createGraphTable("PostTypeSplit", "Post Type"))
                    ) : (
                      <NoDataText />
                    )
                  }
                  tooltip="*Only captured for Influencers with Post Type data available."
                />
              </Grid>
            </Grid>
            <Grid container width={"100%"} my="0.5rem" spacing={3}>
              <Grid item xs={12}>
                <TableWithHeading
                  heading={"By Week Detail"}
                  tableData={
                    weeklyData?.FilteredWeekSplit?.length > 0 ? (
                      tableBody(createWeekGraphTable("FilteredWeekSplit", "Campaign Week"), true)
                    ) : (
                      <NoDataText />
                    )
                  }
                  tooltip="Weeks with no data means no posts were added in that period. This is expected during the kick-off phase and during reporting phase after completion of the campaign."
                />
              </Grid>
              <FormGroup sx={{ paddingLeft: "20px" }}>
                <FormControlLabel
                  control={<Checkbox checked={checked} onChange={handleChange} sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }} />}
                  label={"Show weeks with no publications"}
                />
              </FormGroup>
            </Grid>
          </Box>
        </>
      );
    }
    return content;
  };
  return (
    <>
      <Grid container spacing={1} width={"100%"}>
        <Grid item xs={3}>
          <CheckboxDropdownFilter
            options={locationFilter}
            setOption={filtersChangeHandler}
            name="Location"
            width="280"
            selectedOption={selectedFilters.location}
            filterKey="location"
            helperText="Based on known/shared primary location of the Influencers."
          />
        </Grid>
        <Grid item xs={3}>
          <CheckboxDropdownFilter
            options={languageFilter}
            setOption={filtersChangeHandler}
            name="Language"
            width="280"
            selectedOption={selectedFilters.language}
            filterKey="language"
          />
        </Grid>
        <Grid item xs={3}>
          <CheckboxDropdownFilter
            options={genderFilter}
            setOption={filtersChangeHandler}
            name="Gender"
            width="280"
            selectedOption={selectedFilters.gender}
            filterKey="gender"
            helperText="Based on known/shared gender details of the Influencers. "
          />
        </Grid>
        <Grid item xs={3}>
          <CheckboxDropdownFilter
            options={weekFilter}
            setOption={filtersChangeHandler}
            name="Campaign Weeks"
            width="280"
            selectedOption={selectedFilters.weeks}
            filterKey="weeks"
            checkBox={false}
            weekBox={true}
          />
        </Grid>
      </Grid>
      <br />
      {renderContentHandler()}
    </>
  );
};

EngagementReach.propTypes = {
  currentSection: string.isRequired,
  setCurrentSection: func.isRequired,
  getLastRefreshedTime: string.isRequired,
  getNextRefreshTime: string.isRequired,
};

export default React.memo(EngagementReach);
