import { Cached, Close, FilterList } from "@mui/icons-material";
import { Box, Button, Grid, Tooltip, Backdrop } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../redux/features/user/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllGroupsListForCampaign } from "../../../../services/group";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import DateRangeCustomPicker from "../../../common/daterangepicker";
import ExportFiles from "../../../common/exportfiles";
import GroupDropdown from "../../../common/groupDropdown";
import TaskDropdown from "../../../common/taskDropdown";
import MilestoneDropdown from "../../../common/milestoneDropdown";
import SearchItem from "../../../common/searchitem";
import SelectColumns from "../../../common/selectColumns";
import {
  StyledStaticButton,
  StyledStaticButtonGroup,
} from "../../../common/StyledComponents";
import { notify } from "../../../../redux/features/system/systemAlert";
import { checkRefreshStatusSource } from "../../../../services/publication";
import { IdPlatformMapping } from "../../../../utility/plaformIcons";
import { UpgradePlanToolTipText } from "../../../common/toolTip";
import {
  getMilestonesForCampaign,
} from "../../../../services/tasks";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import CreateCampaignPublications from "../../../../pages/campaign/CreateCampaignPublications";

const CampaignPublicationsFilter = ({
  changeHeaders,
  tableHeaders,
  allSelectedGroups,
  filter,
  selectedStartDate,
  selectedEndDate,
  sendSelectedFilters,
  selectedPlatform,
  getSearchValue,
  sendInformation,
  fileName,
  allAllowedPlatforms,
  disableRow,
  allSelectedTasks,
  allSelectedMilestones,
  setDataRefreshed,
}) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const menu = useSelector((state) => state.menu); //get loggedIn menu state
  const publicationPermissions =
    menu.permissions?.Campaigns?.Fields?.Publications;
  const navigate = useNavigate();
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);

  const campaignId = params.get("id");
  const universal = params.get("universal");
  const dispatch = useDispatch();
  const [platform, setPlatform] = React.useState(selectedPlatform);
  const [selectedGroups, setSelectedGroups] = React.useState(allSelectedGroups); //list of selected groups
  const [filterValue, setFilterValue] = React.useState(filter); //serached value
  const [startDate, setStartDate] = React.useState(selectedStartDate); //start date of the campaign
  const [endDate, setEndDate] = React.useState(selectedEndDate); //end date of the campaign
  const [groupList, setGroupList] = React.useState([]); //all the groups in the platforms
  const [refreshStatus, setRefreshStatus] = React.useState("");
  const [taskList, setTaskList] = React.useState([]); //all the tasks
  const [selectedTasks, setSelectedTasks] = React.useState(allSelectedTasks); //list of selected tasks
  const [mileStoneList, setMileStoneList] = React.useState([]); //all the milestones
  const [selectedMilestones, setSelectedMilestones] = React.useState(
    allSelectedMilestones
  ); //list of selected milestones
  const [addPostModal, setAddPostModal] = React.useState(false); //controlls the add publication Modal
  const [buttonColor, setButtonColor] = React.useState("grey");
  const [filters, setFilters] = React.useState(false);

  React.useEffect(() => {
    setFilterValue(filter);
    setPlatform(selectedPlatform);
    setSelectedGroups(allSelectedGroups);
    setSelectedTasks(allSelectedTasks);
    setSelectedMilestones(allSelectedMilestones);
    getGroupList([IdPlatformMapping[selectedPlatform[0]]]);
    getMilestoneList([IdPlatformMapping[selectedPlatform[0]]]);
  }, [platform, selectedPlatform]);


  React.useEffect(() => {
    let source = checkRefreshStatusSource(campaignId);
    source?.addEventListener(
      "open",
      (e) => {
        source.onmessage = (event) => {
          let data = JSON.parse(event.data);
          if (data.RefStatus !== "1") {
            if (refreshStatus === "active") {
              setRefreshStatus((prevState) => (prevState = "inactive"));
            } else {
              setRefreshStatus((prevState) => (prevState = "inactive"));
            }
          } else {
            setRefreshStatus((prevState) => (prevState = "active"));
          }
        };
      },
      false
    );
  }, [refreshStatus]);

  const getGroupList = async (platforms) => {
    const groups = await getAllGroupsListForCampaign(
      campaignId,
      platforms,
      user,
      dispatch
    );
    if (groups.status === true) {
      setGroupList(groups.response);
    } else {
      if (groups.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: groups.response?.message,
          type: "error",
        })
      );
    }
  };

  const changeColor = () => {
    setButtonColor(isFilterApplied() ? "#007dff" : "grey");
  };

  const isFilterApplied = () => {
    return !filters
  };

  const addFilters = () => {
    setFilters(!filters);
    changeColor();
  };

  const getMilestoneList = async () => {
    const milestones = await getMilestonesForCampaign(
      campaignId,
      user,
      dispatch
    );
    if (milestones.status === true) {
      setMileStoneList(milestones.response);
    } else {
      if (milestones.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: milestones.response?.message,
          type: "error",
        })
      );
    }
  };

  const updateHeaders = (event) => {
    changeHeaders(event);
  };

  const sendExportSelected = (event) => {
    sendInformation({
      export: 1,
      filter: "",
      mimeType: event,
      fileName: fileName,
    });
  };
  const sendSearchedValue = (event) => {
    setFilterValue(event);
    getSearchValue(event);
  };
  const sendSelectedGroups = (event) => {
    setSelectedGroups(event);
  };

  const sendSelectedTasks = (event) => {
    setSelectedTasks(event);
  };

  const sendSelectedMilestones = (event) => {
    setSelectedMilestones(event);
  };

  const sendSelectedDates = (event) => {
    setStartDate(getFormattedDate(event.startDate, "YYYY-MM-DD"));
    setEndDate(getFormattedDate(event.endDate, "YYYY-MM-DD"));
  };

  const go = () => {
    sendSelectedFilters({
      filterValue,
      startDate,
      endDate,
      selectedGroups,
      selectedTasks,
      selectedMilestones,
    });
  };

  const addPublicationsInCampaign = () => {
    setAddPostModal(true);
  };

  const handleCloseModal = () => {
    setAddPostModal(false);
    setDataRefreshed();
  };

  return (
    <>
      {" "}
      <Grid container spacing={1}>
        <Grid item md={7} xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <SearchItem sendSearchedValue={sendSearchedValue} />
            </Grid>
            <Grid item xs={4}>
              <DateRangeCustomPicker
                sendSelectedDates={sendSelectedDates}
                parentStartDate={startDate}
                parentEndDate={endDate}
                needRange={true}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{
                  background:
                    "linear-gradient(180deg, #51a6ff 0%, #007dff 100%)",
                  borderRadius: "8px",
                }}
                onClick={go}
              >
                Go
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={addFilters}
                style={{
                  background: buttonColor,
                  borderRadius: "8px",
                }}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FilterList sx={{ fontSize: "small" }} /> &nbsp;
                <span>Filters</span>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={4}
          position={{ md: "absolute" }}
          right={{ md: 40, xs: 0, sm: 0 }}
          paddingBottom={{ md: 0, sm: 5, xs: 5 }}
        >
          <Grid container spacing={1}>
            {publicationPermissions.AddPublication.Visible && (
              <Tooltip title={disableRow && UpgradePlanToolTipText("credit")}>
                <Grid item md={5}>
                  <Tooltip
                    title={
                      !publicationPermissions.AddPublication.Editable &&
                      UpgradePlanToolTipText()
                    }
                  >
                    <StyledStaticButtonGroup
                      color="primary"
                      exclusive
                      aria-label="Campaign"
                      size="small"
                    >
                      <StyledStaticButton
                        value="active"
                        className={styles.staticButton}
                        disabled={
                          !publicationPermissions.AddPublication.Editable ||
                          disableRow
                        }
                        onClick={addPublicationsInCampaign}
                      >
                        <b>+ Add Publication</b>
                      </StyledStaticButton>
                    </StyledStaticButtonGroup>
                  </Tooltip>
                </Grid>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={1}>
        <Grid item md={7} xs={12} sm={6}>
          <Grid container spacing={2}>
            {filters && (
              <Grid item md={5} xs={12} sm={6}>
                <GroupDropdown
                  groupList={groupList}
                  sendSelectedGroups={sendSelectedGroups}
                  parentSelectedGroup={selectedGroups}
                />
              </Grid>
            )}
            {/* <Grid item md={4} xs={12} sm={6}>
              <MilestoneDropdown milestoneList={mileStoneList} sendSelectedMilestones={sendSelectedMilestones} parentSelectedMilestone={selectedMilestones} />
            </Grid> */}
            {/* <Grid item md={4} xs={12} sm={6}>
              <TaskDropdown taskList={taskList} sendSelectedTasks={sendSelectedTasks} parentSelectedTask={selectedTasks} />
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item md={5} xs={12} sm={6}>
          <Grid
            container
            spacing={2}
            justifyContent={{ xs: "flex-start", md: "flex-end" }}
          >
            <Grid item>
              <SelectColumns
                tableHeaders={tableHeaders.filter(value => value.showSelect)}
                updateHeaders={updateHeaders}
              />
            </Grid>
            <Grid item>
              <ExportFiles sendExportSelected={sendExportSelected} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={addPostModal}
        // closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={addPostModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 800,
              height: "auto",
              bgcolor: "#FAFAFB",
              borderRadius: "15px",
              boxShadow: 24,
              p: 3,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: "28px",
              }}
            >
              <div id="modal-add_user-heading" className="styles_modal_heading">
                <h2
                  id="modal-add_user-heading-text"
                  style={{ fontSize: "2em", fontWeight: "bold", margin: "0" }}
                >
                  Add Publication
                </h2>
              </div>
              <IconButton onClick={()=>setAddPostModal(false)}>
                <Close />
              </IconButton>
            </div>
            <CreateCampaignPublications
              data={allAllowedPlatforms}
              handleCloseModal={handleCloseModal}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

CampaignPublicationsFilter.propTypes = {
  changeHeaders: PropTypes.func.isRequired,
  tableHeaders: PropTypes.array.isRequired,
  allSelectedGroups: PropTypes.array.isRequired,
  selectedStartDate: PropTypes.string.isRequired,
  selectedEndDate: PropTypes.string.isRequired,
  sendSelectedFilters: PropTypes.func.isRequired,
  getSearchValue: PropTypes.func.isRequired,
  sendInformation: PropTypes.func.isRequired,
  mimeType: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  selectedPlatform: PropTypes.array.isRequired,
  allAllowedPlatforms: PropTypes.array.isRequired,
  disableRow: PropTypes.bool,
  allSelectedTasks: PropTypes.array.isRequired,
  allSelectedMilestones: PropTypes.array.isRequired,
};

export default CampaignPublicationsFilter;
