import { RunningWithErrors, ThumbUp, Timer, TrendingDown, TrendingFlat, TrendingUp } from "@mui/icons-material";
import styles from "../styles/internal/Campaign/CampaignList.module.css";

const campaignStatusColors = {
  Live: "#26DE81",
  Draft: "#9c50ef",
  Paused: "#FED330",
  Completed: "#f08995",
  Scheduled: "#51A6FF",
  Archived: "#89f0eb",
  "Archived Draft": "#8995f0",
};
const InfStatusColors = { direct: "#f08995", organic: "#26DE81", "": "#26DE81" };

const InfStatusNameColors = {
  "signup / onboarded": "#1d388c",
  "ready for contract": "#33dd21",
  "contract signed": "#dd21bf",
  "added-direct": "#eca562",
  "": "#62ecb1",
};

const groupCodes = {
  populating: { color: "#FED330", id: 1 },
  active: { color: "#26DE81", id: 2 },
  finished: { color: "#4299E1", id: 4 },
  delete: { color: "#4299E1", id: 3 },
  draft: { color: "#1d388c", id: 5 },
};

const contractCodes = {
  accepted: { color: "#26DE81", id: 1 },
  pending: { color: "#FED330", id: 2 },
  expired: { color: "#f08995", id: 4 },
};

const trendLine = {
  low: (
    <span className={styles.dotRed}>
      <TrendingDown style={{ color: "#E53E3E" }} />
    </span>
  ),
  high: (
    <span className={styles.dotGreen}>
      <TrendingUp style={{ color: "#38A169" }} />
    </span>
  ),
  equal: (
    <span className={styles.dotBlue}>
      <TrendingFlat style={{ color: "#0f9af4" }} />
    </span>
  ),
};

const postStatus = {
  Processing: <Timer htmlColor="#fada0c" />,
  "Successfully Refreshed": <ThumbUp htmlColor="#26DE81" />,
  Success: <ThumbUp htmlColor="#26DE81" />,
  "Invalid URL": <RunningWithErrors htmlColor="red" />,
  "Retrying attempt began": <RunningWithErrors htmlColor="red" />,
};

export { campaignStatusColors, groupCodes, trendLine, postStatus, InfStatusColors, InfStatusNameColors, contractCodes };
