import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PlatformSelectionBox from "../../../common/platformSelectionBox";
import GroupDropdown from "../../../common/groupDropdown";
import { getAllGroupsListForCampaign } from "../../../../services/group";
import { getAllInfluencerListForCampaign } from "../../../../services/influencer";
import DateRangeCustomPicker from "../../../common/daterangepicker";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import InfluencerDropdown from "../../../common/influencerDropdown";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../redux/features/user/userSlice";
import { notify } from "../../../../redux/features/system/systemAlert";
import { PlatformIdMapping } from "../../../../utility/plaformIcons";
import CampaignLinksDestination from "./CampaignLinksDestination";

const CampaignLinksFilter = ({
  allSelectedPlatforms,
  allSelectedGroups,
  allSelectedDestinationIds,
  allSelectedInfluencers,
  selectedStartDate,
  selectedEndDate,
  sendFilterChange,
  allurls,
  getLinksData,
  selectedShorturls,
  setSelectedShorturls,
  updateDates,
  disabled,
}) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const [selectedPlatforms, setSelectedPlatforms] =
    useState(allSelectedPlatforms);
  const [groupList, setGroupList] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState(allSelectedGroups);
  const [selectedDestinationIds, setSelectedDestinationIds] = useState(
    allSelectedDestinationIds
  );
  const [influencerList, setInfluencerList] = useState(allSelectedInfluencers);
  const [startDate, setStartDate] = useState(selectedStartDate);
  const [endDate, setEndDate] = useState(selectedEndDate);
  const [selectedInfluencers, setSelectedInfluencers] = useState([]);
  const [selectedurls, setSelectedurls] = useState([]);
  const [linkSelectAll, setLinkSelectAll] = useState(true);
  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const [initialState, setInitialState] = useState({
    platforms: allSelectedPlatforms,
    groups: allSelectedGroups,
    destinationIds: allSelectedDestinationIds,
    influencers: allSelectedInfluencers,
    startDate: selectedStartDate,
    endDate: selectedEndDate,
    shorturls: selectedShorturls,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  useEffect(() => {
    const loadInitialData = async () => {
      setIsLoading(true);
      await getGroupList("all");
      setIsLoading(false);
      setInitialDataLoaded(true);
    };

    loadInitialData();
  }, [params.get("id")]);

  useEffect(() => {
    if (initialDataLoaded) {
      setInitialState({
        platforms: selectedPlatforms,
        groups: selectedGroups,
        destinationIds: selectedDestinationIds,
        influencers: selectedInfluencers,
        startDate: startDate,
        endDate: endDate,
        shorturls: selectedShorturls,
      });
      setIsFilterChanged(false);
    }
  }, [initialDataLoaded]);

  useEffect(() => {
    if (initialDataLoaded) {
      const hasChanged =
        !areArraysEqual(selectedPlatforms, initialState.platforms) ||
        !areArraysEqual(selectedGroups, initialState.groups) ||
        !areArraysEqual(selectedDestinationIds, initialState.destinationIds) ||
        !areArraysEqual(selectedInfluencers, initialState.influencers) ||
        startDate !== initialState.startDate ||
        endDate !== initialState.endDate ||
        !areArraysEqual(selectedShorturls, initialState.shorturls);

      setIsFilterChanged(hasChanged);
    }
  }, [selectedPlatforms, selectedGroups, selectedDestinationIds, selectedInfluencers, startDate, endDate, selectedShorturls, initialDataLoaded]);

  useEffect(() => {
    getLinksData(
      selectedGroups?.map((value) => value.id),
      selectedPlatforms?.map((value) => PlatformIdMapping[value]),
      selectedDestinationIds?.map((value) => value.urlId),
      selectedInfluencers?.map((value) => value.Iid)
    );
  }, [
    selectedGroups,
    selectedPlatforms,
    selectedDestinationIds,
    selectedInfluencers,
  ]);

  const sendSelectedPlatforms = (event) => {
    setSelectedPlatforms(event);
    setIsFilterChanged(true);
    if (event?.length > 0) {
      getGroupList(event);
    } else {
      getGroupList("all");
    }
  };
  const sendSelectedGroups = (event) => {
    const ids = [];
    if (event?.length > 0) {
      event.forEach((group) => {
        ids.push(group.id);
      });
      getInfluencerList(ids);
    } else {
      getGroupList("all");
    }
    setSelectedGroups(event);
    setIsFilterChanged(true);
  };
  const sendSelectedDestinationIds = (event) => {
    setSelectedDestinationIds(event);
    setIsFilterChanged(true);
  };
  const getGroupList = async (platforms) => {
    const groups = await getAllGroupsListForCampaign(
      params.get("id"),
      platforms,
      user,
      dispatch
    );
    if (groups.status === true) {
      const ids = groups.response?.map((group) => group.id);
      await getInfluencerList(ids);
      setGroupList(groups.response);
      setSelectedGroups([]);
    } else {
      if (groups.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: groups.response?.message,
          type: "error",
        })
      );
    }
  };

  const getInfluencerList = async (ids) => {
    const influencers = await getAllInfluencerListForCampaign(
      ids,
      "all",
      user,
      dispatch
    );
    if (influencers.status === true) {
      setInfluencerList(influencers.response);
    }
  };

  const sendSelectedDates = (event) => {
    const startDate = getFormattedDate(event.startDate, "YYYY-MM-DD");
    const endDate = getFormattedDate(event.endDate, "YYYY-MM-DD");
    setStartDate(startDate);
    setEndDate(endDate);
    updateDates({ startDate, endDate });
    setIsFilterChanged(true);
  };

  const sendSelectedInfluencer = (event) => {
    setSelectedInfluencers(event);
    setIsFilterChanged(true);
  };

  const selecturls = (value) => {
    // const jsonObject = event?.map(JSON.stringify);
    // const uniqueSet = new Set(jsonObject);
    // const uniqueArray = Array.from(uniqueSet)?.map(JSON.parse);
    // setSelectedurls(uniqueArray);
    let selectAllPresent = false;
    for (let i = 0; i < value?.length; i++) {
      if (value[i].id === -1) {
        selectAllPresent = true;
        break;
      }
    }
    if (selectAllPresent) {
      if (linkSelectAll) {
        setLinkSelectAll(false);
        setSelectedShorturls([]);
      } else {
        const values = allurls?.filter((val) => val.id !== -1);
        setSelectedShorturls(values);
        setLinkSelectAll(true);
      }
      return;
    }
    let selectAll = false;
    if (value?.length === allurls?.length) {
      selectAll = true;
    }
    setSelectedShorturls(value);
    setLinkSelectAll(selectAll);
    setIsFilterChanged(true);
  };

  const applyFilters = () => {
    sendFilterChange({
      startDate,
      endDate,
      selectedGroups,
      selectedDestinationIds,
      selectedInfluencers,
      selectedPlatforms,
      selectedShorturls,
    });
  };

  // Helper function to compare arrays
  const areArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) return false;
    }
    return true;
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={2} />
        <Grid item md={3}>
          {" "}
          <PlatformSelectionBox
            sendSelectedPlatforms={sendSelectedPlatforms}
            parentPlatforms={selectedPlatforms}
            disabled={disabled}
          />
        </Grid>
        <Grid item md={3}>
          {" "}
          <GroupDropdown
            groupList={groupList}
            sendSelectedGroups={sendSelectedGroups}
            parentSelectedGroup={selectedGroups}
            disabled={disabled}
          />
        </Grid>
        <Grid item md={3}>
          <CampaignLinksDestination
            platforms={selectedPlatforms}
            groups={selectedGroups}
            selectedDestinationIds={selectedDestinationIds}
            sendSelectedDestinationIds={sendSelectedDestinationIds}
            disabled={disabled}
          />
        </Grid>
        <Grid item md={1} />
        <Grid item md={2} />
        <Grid item md={3}>
          {" "}
          <InfluencerDropdown
            influencerList={influencerList}
            sendSelectedInfluencer={sendSelectedInfluencer}
            parentSelectedInfluencer={selectedInfluencers}
            disabled={disabled}
          />{" "}
        </Grid>
        <Grid item md={3}>
          <Autocomplete
            id="free-solo-2-demo"
            disableCloseOnSelect
            multiple
            limitTags={0}
            disableClearable
            options={[{ id: -1, shortUrl: "Select All" }, ...allurls]}
            value={selectedShorturls}
            disabled={allurls ? false || disabled : true}
            onChange={(_, newValue) => {
              selecturls(newValue);
            }}
            getOptionLabel={(option) => option.shortUrl}
            renderTags={(selected, _, state) =>
              state.popupOpen ? "" : `+${selected?.length}`
            }
            renderOption={(props, option, { selected }) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={option.id === -1 ? linkSelectAll : selected}
                />
                {option.shortUrl}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selected Short urls"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    minWidth: "7vw",
                    marginRight: "5px",
                    borderRadius: "10px",
                  },
                }}
                size="small"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={3}>
          {" "}
          <DateRangeCustomPicker
            sendSelectedDates={sendSelectedDates}
            parentStartDate={startDate}
            parentEndDate={endDate}
            disabled={disabled}
            needRange={true}
          />
        </Grid>
        <Grid item md={1}>
          <Button
            variant="contained"
            style={{
              background: "linear-gradient(180deg, #51a6ff 0%, #007dff 100%)",
              borderRadius: "8px",
            }}
            onClick={applyFilters}
            disabled={allurls?.length === 0 || disabled || !isFilterChanged}
          >
            Go
          </Button>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <Grid container spacing={2}></Grid>
        </Grid>
      </Grid>
    </>
  );
};
CampaignLinksFilter.propTypes = {
  allSelectedPlatforms: PropTypes.array.isRequired,
  allSelectedGroups: PropTypes.array.isRequired,
  allSelectedDestinationIds: PropTypes.array.isRequired,
  allSelectedInfluencers: PropTypes.array.isRequired,
  selectedStartDate: PropTypes.string.isRequired,
  selectedEndDate: PropTypes.string.isRequired,
  sendFilterChange: PropTypes.func.isRequired,
  allurls: PropTypes.array.isRequired,
  getLinksData: PropTypes.func.isRequired,
  selectedShorturls: PropTypes.array.isRequired,
  setSelectedShorturls: PropTypes.func.isRequired,
  updateDates: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CampaignLinksFilter;
