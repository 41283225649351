import { Add, ArrowDropDownRounded, ArrowForward, Info } from "@mui/icons-material";
import { Box, Button, Grid, Menu, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../redux/features/system/systemAlert";
import { createNewUsers, getRolesData } from "../../services/account";
import { getAccountInformation } from "../../services/settings";
import RoleMenu from "./RoleMenu";
import { checkForCredit, getCreditInfo, getPlansInfo } from "../../services/credit";
import Loader from "../../components/common/loader";

function InviteNewUsers() {
  const [rowCount, setRowCount] = useState(1);
  const [rows, setRows] = useState([]);
  const [name, setName] = useState([""]);
  const [email, setEmail] = useState([""]);
  const [invalidEmail, setInvalidEmail] = useState([false]);
  const [role, setRole] = useState([0]);
  const [rolesData, setRolesData] = useState([]);
  const [companyName, setCompanyName] = useState();
  const [anchorEl, setAnchorEl] = useState([null]);
  const [disableIncrRow, setDisableIncrRow] = useState(false);
  const [disableSendRow, setDisableSendRow] = useState(false);
  const [creditInfo, setCreditInfo] = useState({});
  const [maxLimit, setMaxLimit] = useState(null);
  const [credit, setCredit] = useState(null);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const getRoles = async () => {
    const data = {
      email: user.email,
      role: user.role,
      agencyId: user.agencyId.toString(),
    };
    const result = await getRolesData(data, user, dispatch);

    if (result.status === true) {
      const rolesData = result.response.roles;
      setRolesData(rolesData);
    }
  };

  const getCompanyName = async () => {
    const response = await getAccountInformation(user, dispatch);

    if (response.status === true) {
      const data = response?.response[0];
      setCompanyName(data.name);
    } else {
      dispatch(
        notify({
          message: response?.response?.message,
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    getRoles();
    getCompanyName();
    checkForCreditValue();
  }, []);

  const checkForCreditValue = async () => {
    const data = {
      name: ["Account.Invite New Users"],
      accountId: user.agencyId,
      clientId: user.clientId,
    };
    const response = await checkForCredit(data, user, dispatch);
    const inviteCredits = response?.response.message[data.name[0]];
    setCredit(parseInt(inviteCredits));
    if (isNaN(parseInt(inviteCredits))) {
      setDisableIncrRow(false);
      setDisableSendRow(false);
    } else if (parseInt(inviteCredits) > 1) {
      setDisableIncrRow(false);
      setDisableSendRow(false);
    } else if (parseInt(inviteCredits) === 1) {
      setDisableIncrRow(true);
      setDisableSendRow(false);
    } else {
      setDisableIncrRow(true);
      setDisableSendRow(true);
    }

    const creditInfo = await getCreditInfo(data, user, dispatch);
    if (creditInfo.status) {
      setCreditInfo(creditInfo.response);
      getAllPlans(creditInfo.response);
    }
  };

  const getAllPlans = async (creditInf) => {
    const plans = [2, 3, 6, 12];
    const data = {
      planIds: plans,
    };
    const planInfo = await getPlansInfo(data, user, dispatch);
    if (planInfo.status) {
      if (!isNaN(planInfo.response[creditInf.planid]["Account"]?.["Invite New Users"]?.["CreditsAvailable"]))
        setMaxLimit(planInfo.response[creditInf.planid]["Account"]?.["Invite New Users"]?.["CreditsAvailable"]);
    }
  };

  useEffect(() => {
    const incrementRows = async () => {
      let error = false;
      if (parseInt(credit) - 1 < rowCount) {
        dispatch(
          notify({
            message: `You can invite only ${rowCount} under your plan.`,
            type: "info",
          })
        );
        setDisableIncrRow(disableIncrRow);
      } else {
        for (let i = 0; i < rowCount; i++) {
          if (!name[i].trim() || !email[i].trim()) {
            return;
          }
        }
        //user should be invited from same domain (ai-fluence.com can invite ai-fluence.com users only)
        const domain = getUserDomain();
        email.forEach((em) => {
          if (em.includes("gmail")) {
            error = true;
            dispatch(
              notify({
                message: `You cannot invite people from gmail. Please use business account to invite`,
                type: "error",
              })
            );
            return;
          }
          if (!em.includes(domain) && em !== "") {
            error = true;
            dispatch(
              notify({
                message: `You can invite people from ${domain} only`,
                type: "error",
              })
            );
            return;
          }
        });
        error === false && setRowCount(rowCount + 1);
      }

      if (validateEmail()) {
        return;
      }
      if (error) {
        return;
      }
      setCredit((credit) => credit - 1);
      if (credit - 1 === 1) {
        setDisableIncrRow(true);
      }
      setName((name) => [...name, ""]);
      setEmail((email) => [...email, ""]);
      setRole((role) => [...role, 2]);
      setAnchorEl((anchorEl) => [...anchorEl, null]);
    };

    const getUserDomain = () => {
      const index = user.email.indexOf("@") + 1;
      return user.email.slice(index);
    };
    const handleName = (value, index) => {
      let newName = [...name];
      newName[index] = value;
      setName(newName);
    };

    const handleEmail = (value, index) => {
      let newEmail = [...email];
      newEmail[index] = value;
      setEmail(newEmail);
    };

    const handleMember = (event, index) => {
      const newAnchorEl = [...anchorEl];
      if (newAnchorEl[index] === null) {
        newAnchorEl[index] = event.currentTarget;
      } else {
        newAnchorEl[index] = null;
      }

      setAnchorEl(newAnchorEl);
    };

    const handleMenuMember = (i) => {
      return (index) => {
        const newRole = [...role];
        newRole[i] = index;
        setRole(newRole);
        handleClose();
      };
    };

    const handleClose = () => {
      const anchorEl = [];
      for (let i = 0; i < rowCount; i++) {
        anchorEl.push(null);
      }
      setAnchorEl(anchorEl);
    };

    const open = anchorEl?.map((val) => val !== null);

    if (rolesData?.length > 0) {
      const rows = [];
      for (let i = 0; i < rowCount; i++) {
        rows.push(
          <Grid key={i} container item spacing={1} xs={12}>
            <Grid item xs={8} sm={3}>
              <TextField
                style={{ width: "95%", margin: "auto" }}
                variant="filled"
                value={name[i]}
                onChange={(event) => handleName(event.target.value, i)}
                label="Full Name"
                type="text"
                required
                inputProps={{
                  style: {
                    height: "17px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={8} sm={6}>
              <TextField
                style={{ width: "95%", margin: "auto" }}
                variant="filled"
                value={email[i]}
                onChange={(event) => handleEmail(event.target.value, i)}
                onBlur={validateEmail}
                label="Email"
                type="email"
                helperText={invalidEmail[i] ? "Invalid Email" : ""}
                error={invalidEmail[i]}
                required
                inputProps={{
                  style: {
                    height: "17px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={8} sm={1.5}>
              <Button
                style={{
                  width: "95%",
                  borderRadius: "10px",
                  backgroundColor: "#E7E8EA",
                  color: "#464E5F",
                  height: "50px",
                }}
                variant="contained"
                onClick={(event) => handleMember(event, i)}
                endIcon={<ArrowDropDownRounded style={{ transform: "scale(1.5)" }} />}
              >
                {rolesData[role[i]] ? rolesData[role[i]]?.title : rolesData[role[0]]?.title}
              </Button>
              <RoleMenu
                roles={rolesData}
                anchorEl={anchorEl[i]}
                open={open[i]}
                handleClose={handleClose}
                handleMenuMember={handleMenuMember(i)}
              />
            </Grid>
            {i === rowCount - 1 ? (
              <Grid item xs={8} sm={1.5}>
                <Button
                  style={{
                    width: "95%",
                    borderRadius: "10px",
                    backgroundColor: "#605BFF",
                    height: "50px",
                  }}
                  variant="contained"
                  onClick={incrementRows}
                  startIcon={<Add />}
                  disabled={disableIncrRow}
                >
                  Add Row
                </Button>
              </Grid>
            ) : (
              <Grid item xs={8} sm={1.5} />
            )}
          </Grid>
        );
      }

      setRows(rows);
    }
  }, [rowCount, anchorEl, name, email, role, rolesData, invalidEmail, disableIncrRow, disableSendRow]);

  const validateEmail = () => {
    const regex =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    const invalidEmail = [];
    let result = false;

    for (let i = 0; i < rowCount; i++) {
      if (email[i].trim()?.length === 0) {
        invalidEmail.push(false);
        continue;
      }
      const invalid = !regex.test(email[i]);
      invalidEmail.push(invalid);

      if (invalid) {
        result = true;
      }
    }

    setInvalidEmail(invalidEmail);

    return result;
  };

  const handleSend = async () => {
    const data = {};
    const users = [];
    for (let i = 0; i < rowCount; i++) {
      const user = {};
      user.name = name[i];
      user.email = email[i];
      let result = rolesData[role[i]] ? rolesData[role[i]] : rolesData[role[0]];
      user.role = result.title;
      user.roleId = result.id;
      if (validateEmail()) {
        return;
      }

      if (user.name.trim() !== "" && user.email.trim() !== "") {
        users.push(user);
      }
    }

    if (users?.length === 0) {
      return;
    }

    data.users = users;
    data.companyName = companyName;
    data.agencyId = user.agencyId.toString();
    data.clientId = user.clientId.toString();
    data.billingAdded = true;
    data.addedBy = user.email;
    const response = await createNewUsers(data, user, dispatch);

    if (response.status === true) {
      setCredit((credit) => credit - 1);
      if (credit - 1 === 1) {
        setDisableIncrRow(true);
      }
      if (credit === 1) {
        setDisableSendRow(true);
      }
      setRowCount(1);
      setName([""]);
      setEmail([""]);
      setInvalidEmail([false]);
      setRole([2]);

      dispatch(
        notify({
          message: "New Users Invited",
          type: "success",
        })
      );
    }
  };

  return (
    <>
      {credit === null || maxLimit === null || rows.length === 0 ? (
        <Loader />
      ) : (
        <>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              color: "#757575",
              marginBottom: "25px",
            }}
          >
            User Details
          </Typography>
          {!isNaN(credit) && (
            <Box
              style={{
                backgroundColor: "#FAFAFB",
                borderRadius: "10px",
                width: "calc(100% - 10px)",
                margin: "auto",
                marginBottom: "25px",
              }}
            >
              <Info color="primary" style={{ margin: "10px" }} />
              <span
                style={{
                  fontSize: "1rem",
                  fontWeight: 400,
                  color: "#757575",
                  marginLeft: "15px",
                }}
              >
                {credit === 0
                  ? `You have exhausted user limit of ${maxLimit} under your current plan. Please upgrade to invite more team members.`
                  : `You can invite up to ${credit} additional team ${credit > 1 ? "members" : "member"
                  } according to your current plan.`}
              </span>
            </Box>
          )}

          <Grid
            container
            spacing={2}
            style={{
              backgroundColor: "#FAFAFB",
              borderRadius: "10px",
              width: "calc(100% - 10px)",
              margin: "auto",
            }}
          >
            {rows}
            <Grid container item xs={12} spacing={1}>
              <Grid item sm={10.5} />
              <Grid item sm={1.5}>
                <Button
                  style={{
                    width: "95%",
                    borderRadius: "10px",
                    marginBottom: "15px",
                    marginTop: "35px",
                    height: "50px",
                  }}
                  variant="contained"
                  color="success"
                  startIcon={<ArrowForward />}
                  onClick={handleSend}
                  disabled={disableSendRow}
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default InviteNewUsers;
