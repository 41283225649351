import CampaignPublicationsFilter from "./CampaignPublicationsFilter";
import React, { useEffect } from "react";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import configs from "../../../../config/main.json";
import {
  IdPlatformMapping,
  PlatformIdMapping,
} from "../../../../utility/plaformIcons";
import { Alert, Avatar, Box, Grid, Tooltip, Typography } from "@mui/material";
import CampaignPublicationsActionButton from "./CampaignPublicationsActionButton";
import { tableHeaders } from "./CommonAssets";
import { getAllPublicationsForCampaign } from "../../../../services/publication";
import { useLocation } from "react-router-dom";
import { InfoToolTip, InfoToolTipPreview } from "../../../common/toolTip";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import moment from "moment";
import { getEngagementBreakup } from "../../../../utility/postOpertions";
import { postStatus } from "../../../../utility/status";
import {
  convertToInternationalNumberSystem,
  postTypeMapping,
} from "../../../../utility/stringOperations";
import {
  onDownloadHandle,
  downloadNotification,
} from "../../../../utility/exportData";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignName } from "../../../../utility/sessionData";
import { notify } from "../../../../redux/features/system/systemAlert";
import CampaignPublicationTab from "./CampaignPublicationsTabs";
import {
  Article,
  HistoryToggleOff,
  Lightbulb,
  Mic,
  Poll,
  Videocam,
  Warning,
} from "@mui/icons-material";
import PrivatePublication from "../../../../guides/PrivatePublication";
import { checkForCredit, getCreditInfo } from "../../../../services/credit";
import { getCampaignOverview } from "../../../../services/campaignAnalytics";
import Loader from "../../../common/loader";
import { getTimeZone } from "../../../../services/common";
import DummyCampaign from "../../../common/DummyCampaign";
import { stringAvatar } from "../campaignDrafts/CommonAssets";

const CampaignPublicationsSnapshot = () => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const data = location.state;
  const [allPlatforms, setAllPlatforms] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [allAllowedPlatforms, setAllAllowedPlatforms] = React.useState([]);
  const [dataFound, setDataFound] = React.useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = React.useState([]); //all the platforms
  const [bodyColumns, setBodyColumns] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState(10);
  const [sortCol, setSortCol] = React.useState("a.postedOn"); //sort by lastRefreshedOn by default
  const [sortOrder, setSortOrder] = React.useState("desc"); //sort in descending order
  const [totalRows, setTotalRows] = React.useState(10);
  const [selectedGroup, setSelectedGroup] = React.useState([]);
  const [filter, setFilter] = React.useState("");
  const [startDate, setStartDate] = React.useState(
    getFormattedDate(moment().subtract(6, "months").toDate(), "YYYY-MM-DD")
  ); //select campaign duration for 7 days
  const [endDate, setEndDate] = React.useState(
    getFormattedDate(moment().toDate(), "YYYY-MM-DD")
  );
  const [headers, setHeaders] = React.useState(tableHeaders);
  const [mimeType, setMimeType] = React.useState("");
  const [campaignName] = React.useState(getCampaignName());
  const [fileName, setFileName] = React.useState("");
  const dispatch = useDispatch();
  const [campaignStartDate, setCampaignStartDate] = React.useState("");
  const [dateDiff, setDateDiff] = React.useState(false);
  const [openPrivatePublicationGuide, setOpenPrivatePublicationGuide] =
    React.useState(false);
  const [disableRow, setDisableRow] = React.useState(false);
  const [postCount, setPostCount] = React.useState(0);
  const [influencerCount, setInfluencerCount] = React.useState(0);
  const [engagementCount, setEngagementCount] = React.useState(0);
  const [engagementDone, setEngagementDone] = React.useState(0);
  const [selectedTasks, setSelectedTasks] = React.useState([]);
  const [selectedMilestones, setSelectedMilestones] = React.useState([]);

  const cid = params.get("id");
  const timeZone = getTimeZone();
  const universal = params.get("universal");
  useEffect(() => {
    checkForCreditValue();
    loadBasicData();
  }, [params.get("id")]);

  useEffect(() => {
    // Once selectedPlatforms has been set, perform further actions
    if (selectedPlatforms?.length > 0) {
      getPublicationData(
        +params.get("id"),
        selectedPlatforms,
        selectedGroup?.length > 0 ? selectedGroup : [-1],
        page,
        rows,
        sortCol,
        sortOrder,
        0,
        filter,
        startDate,
        endDate,
        mimeType,
        fileName,
        moment(startDate),
        selectedTasks,
      );
      // Perform other actions here
    }
  }, [selectedPlatforms]);

  const sendSelectedFilters = (event) => {
    setStartDate(event.startDate);
    setEndDate(event.endDate);
    setSelectedGroup(event.selectedGroups);
    setSelectedTasks(event.selectedTasks);
    setSelectedMilestones(event.selectedMilestones);
    setFilter(event?.filterValue);
    setSelectedPlatforms(
      event.selectedPlatforms ? event.selectedPlatforms : selectedPlatforms
    );
    getPublicationData(
      +params.get("id"),
      event.selectedPlatforms ? event.selectedPlatforms : selectedPlatforms,
      event.selectedGroups?.length > 0 ? event.selectedGroups : [-1],
      0,
      rows,
      sortCol,
      sortOrder,
      0,
      event?.filterValue,
      event.startDate,
      event.endDate,
      event.mimeType,
      event.fileName,
      campaignStartDate,
      timeZone,
      event.selectedTasks,
    );
  };

  const loadBasicData = async () => {
    setLoading(true);
    setDataFound(false);
    const campaignDetails = await getCampaignOverview(cid, user, dispatch);
    if (campaignDetails.response) {
      if (campaignDetails.response.overallSummary === null) {
        setLoading(false);
      }
      const { platforms, addedPlatforms, campaignStart, totalEngagements } =
        campaignDetails.response.overallSummary[0];

      setLoading(false);
      const selectedPlatformId = PlatformIdMapping[addedPlatforms[0]];
      setDataFound(true);
      setCampaignStartDate(moment(campaignStart));
      setAllAllowedPlatforms(platforms);
      setAllPlatforms(addedPlatforms);
      setSelectedPlatforms([selectedPlatformId]);
      setEngagementDone(totalEngagements);

      const groups = [];
      if (data !== null) {
        groups.push(data?.data?.id);
        const groupObject = {
          id: data?.data?.id,
          name: data?.data?.groupName,
          platform: data?.data?.platform,
        };
        sendSelectedFilters({
          selectedGroups: [groupObject],
          selectedPlatforms: [PlatformIdMapping[data?.data?.platform]],
          startDate,
          endDate,
          filterValue: filter,
          mimeType: fileName,
        });
      }
    }
  };
  //get information on publicatiosn
  const getPublicationData = async (
    id,
    selectedPlatforms,
    selectedGroup,
    page,
    rows,
    col,
    order,
    exportValue,
    filter,
    startDate,
    endDate,
    mimeType,
    fileName,
    campaignDate,
    timeZone,
    selectedTasks,
  ) => {
    let groupIds = [-1];
    if (selectedGroup[0] != -1) {
      groupIds = selectedGroup?.map((group) => group.id);
    }
    let taskIds = [-1];
    if (typeof selectedTasks !== 'undefined' && selectedTasks[0] != -1) {
      taskIds = selectedTasks?.map((task) => parseInt(task.id));
    }
    if (exportValue === 1) {
      downloadNotification(dispatch);
      const fileName = `Publications_${campaignName}.${mimeType}`;
      setFileName(fileName);
      const downloadData = await getAllPublicationsForCampaign(
        id,
        [-1],
        [-1],
        page + 1,
        rows,
        col,
        order,
        exportValue,
        filter,
        startDate,
        endDate,
        mimeType,
        fileName,
        timeZone,
        taskIds,
        user,
        dispatch
      );
      if (downloadData.response !== undefined) {
        onDownloadHandle(new Blob([downloadData.response]), fileName);
      } else {
        dispatch(
          notify({
            message: "No data to download",
            type: "error",
          })
        );
      }
    } else {
      setLoading(true);
      const publications = await getAllPublicationsForCampaign(
        id,
        selectedPlatforms,
        groupIds,
        page + 1,
        rows,
        col,
        order,
        exportValue,
        filter,
        startDate,
        endDate,
        "",
        fileName,
        timeZone,
        taskIds,
        user,
        dispatch
      );
      if (
        publications.response.records &&
        publications.response.records?.length > 0
      ) {
        setTotalRows(publications.response.total_records);
        createData(publications.response.records, campaignDate);
        setDataFound(true);
        setLoading(false);
      } else {
        setLoading(false);
        setDataFound(false);
      }
    }
  };

  const setDataRefreshed = () => {
    loadBasicData();
    getPublicationData(
      +params.get("id"),
      selectedPlatforms,
      selectedGroup?.length > 0 ? selectedGroup : [-1],
      0,
      rows,
      sortCol,
      sortOrder,
      0,
      filter,
      startDate,
      endDate,
      mimeType,
      fileName,
      campaignStartDate,
      timeZone,
      selectedTasks,
    );
  };
  //prepare the data for the table
  const createData = (records, campaignDate) => {
    const columns = [];
    records.forEach((data) => {
      const {
        id,
        postedOn,
        postType,
        postUrl,
        postId,
        photo_url,
        profileUrl,
        infName,
        userName,
        title,
        milestone,
        engagement,
        reach,
        engagementRate,
        groupName,
        status,
        lastRefreshedOn,
        platform,
      } = data;
      const dateDifference = moment(campaignDate).diff(
        moment(
          moment(postedOn.includes(" - ") && postedOn.split(" - ")[1]).format(
            "YYYY-MM-DD"
          )
        )
      );
      if (dateDifference > 0) {
        setDateDiff(true);
      }

      const postTypeName = postTypeMapping(postType);
      let object = {
        id: id,
        post: (
          <Grid container spacing={1}>
            <Grid item xs={2}>
              {dateDifference > 0 && (
                <Tooltip
                  title={
                    "Publication date is before the start of campaign. Please edit your campaign's start date to track this publication"
                  }
                >
                  <Warning
                    sx={{
                      width: 25,
                      height: 25,
                      marginTop: 1.5,
                      color: "#FED330",
                    }}
                  />
                </Tooltip>
              )}
            </Grid>

            {/* <Grid item xs={8} sx={{ marginTop: 1 }}>
              <Grid container>
                <Tooltip title={postUrl}>
                  <Grid item xs={12} className={styles.bodycell} style={{ marginTop: 5 }}>
                    <a href={postUrl} target="_blank">
                      <p style={{ marginTop: 5 }}>{postId?.substring(0, 15)}...</p>
                    </a>
                  </Grid>
                </Tooltip>
              </Grid>
            </Grid> */}

            <Grid item xs={8} sx={{ marginTop: 1 }}>
              <Grid container>
                <Tooltip title={postUrl}>
                  {postTypeName === "Story" ? (
                    <Grid
                      item
                      xs={12}
                      className={styles.bodycell}
                      style={{ marginTop: 8 }}
                    >
                      <a
                        href={postUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p style={{ marginTop: 5 }}>
                          {postId?.substring(0, 15)}...
                        </p>
                      </a>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      className={styles.bodycell}
                      style={{ marginTop: 8 }}
                    >
                      <InfoToolTipPreview
                        postUrl={postUrl}
                        platform={platform}
                        postId={postId}
                        status={status}
                      />
                    </Grid>
                  )}
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        ),
        Posttype: (
          <Grid>
            <Tooltip title={postTypeName}>
              <div>
                {postTypeName === "Post" && (
                  <Article fontSize={"small"} sx={{ marginTop: "10px" }} />
                )}
                {postTypeName === "Poll" && (
                  <Poll fontSize={"small"} sx={{ marginTop: "10px" }} />
                )}
                {postTypeName === "Space" && (
                  <Mic fontSize={"small"} sx={{ marginTop: "10px" }} />
                )}
                {postTypeName === "Reel" && (
                  <Videocam fontSize={"small"} sx={{ marginTop: "10px" }} />
                )}
                {postTypeName === "Story" && (
                  <HistoryToggleOff
                    fontSize={"small"}
                    sx={{ marginTop: "10px" }}
                  />
                )}
              </div>
            </Tooltip>
          </Grid>
        ),
        influencerAvatar: (
          <Grid item md={3} sm={6}>
            <Avatar
              variant="circle"
              src={photo_url || ""}
              {...stringAvatar(infName)}
              onError={(e) => {
                e.target.src = configs.PROFILE_IMG;
              }}
            />
          </Grid>
        ),
        influencer: (
          <Grid container>
            {infName == "" ? (
              <Grid item xs={12} style={{ marginTop: 9 }}>
                <p className={styles.subbodycell}>Collecting...</p>
              </Grid>
            ) : (
              <Grid item xs={12} style={{ marginTop: 9 }}>
                <p className={styles.bodycell} style={{ marginBottom: 5 }}>
                  {profileUrl !== "" ? (
                    <a href={profileUrl} target="_blank">
                      {infName}
                    </a>
                  ) : (
                    <p>{infName}</p>
                  )}
                </p>
                <p className={styles.subbodycell}>@{userName}</p>
              </Grid>
            )}
          </Grid>
        ),
        title: title == "" ? "-" : title,
        milestone: milestone == "" ? "-" : milestone,
        createdAt: (
          <Grid container spacing={1}>
            <Grid item xs={12} className={styles.bodycell}>
              {postedOn.includes(" - ")
                ? postedOn.split(" - ")[1]
                : postedOn.split("at")[0]}
            </Grid>
          </Grid>
        ),

        totalEngagement: (
          <Grid container spacing={1}>
            <Grid item xs={1} className={styles.bodycell} />
            <Grid item xs={10} className={styles.bodycell}>
              {convertToInternationalNumberSystem(engagement)}
              &nbsp;&nbsp;
              {engagement > 0 && (
                <InfoToolTip text={getEngagementBreakup(data)} />
              )}
            </Grid>
          </Grid>
        ),
        reach: (
          <Grid container spacing={1}>
            <Grid item xs={2} className={styles.bodycell}></Grid>
            <Grid item xs={3} className={styles.bodycell}>
              {convertToInternationalNumberSystem(reach)}
            </Grid>
          </Grid>
        ),
        "er%": <p className={styles.bodycell}>{engagementRate}%</p>,
        groupName: groupName,
        status: (
          <Grid container>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              {postStatus[status]}
            </Grid>
            <Grid
              item
              xs={12}
              className={styles.subbodycell}
              style={{ textAlign: "center" }}
            >
              {lastRefreshedOn.includes("-")
                ? lastRefreshedOn.split("-")[1]
                : lastRefreshedOn.split(" at ")[1]}
            </Grid>
          </Grid>
        ),
        action: (
          <Grid container spacing={1}>
            <Grid item>
              <CampaignPublicationsActionButton
                influencerAccount={data}
                setDataRefreshed={setDataRefreshed}
              />
            </Grid>
          </Grid>
        ),
      };
      columns.push(object);
    });

    setBodyColumns(columns);
  };

  const changeHeaders = (event) => {
    setHeaders(event);
  };

  //function that caters to page changes
  const sendInformation = (event) => {
    const pageNo = event.page ? event.page : page;
    const rowsPerPage = event.rowsPerPage ? event.rowsPerPage : rows;
    const orderBy = event.orderBy ? event.orderBy : sortCol;
    const order = event.order ? event.order : sortOrder;
    const mimeTypeValue = event.mimeType ? event.mimeType : mimeType;
    const fileNameValue = event.fileName ? event.fileName : fileName;
    const exportValue = event.export ? event.export : 0;
    setPage(pageNo);
    setRows(rowsPerPage);
    setSortOrder(order);
    setSortCol(orderBy);
    setMimeType(mimeTypeValue);
    setFileName(fileNameValue);
    getPublicationData(
      +params.get("id"),
      selectedPlatforms,
      selectedGroup?.length > 0 ? selectedGroup : [-1],
      pageNo,
      rowsPerPage,
      orderBy,
      order,
      exportValue,
      filter,
      startDate,
      endDate,
      mimeTypeValue,
      fileNameValue,
      campaignStartDate,
      timeZone,
      selectedTasks,
    );
  };

  const handleTabChange = (event) => {
    setSelectedPlatforms([event]);
    getPublicationData(
      +params.get("id"),
      [event],
      selectedGroup?.length > 0 ? selectedGroup : [-1],
      0,
      rows,
      sortCol,
      sortOrder,
      0,
      filter,
      startDate,
      endDate,
      mimeType,
      fileName,
      campaignStartDate,
      timeZone,
      selectedTasks,
    );
  };

  const getSearchValue = (event) => {
    setFilter(event);
    setTimeout(() => {
      getPublicationData(
        +params.get("id"),
        selectedPlatforms,
        selectedGroup?.length > 0 ? selectedGroup : [-1],
        0,
        rows,
        sortCol,
        sortOrder,
        0,
        event,
        startDate,
        endDate,
        mimeType,
        fileName,
        campaignStartDate,
        selectedTasks,
      );
    }, 500);
  };

  const checkForCreditValue = async () => {
    const data = {
      accountId: user.agencyId,
      clientId: user.clientId,
    };
    const response = await getCreditInfo(data, user, dispatch);
    const publication =
      response?.response.Campaigns.Fields.Publications.CreditsAvailable;
    const influencer =
      response?.response.Campaigns.Fields.Influencers.CreditsAvailable;
    const engagement =
      response?.response.Campaigns.Fields.Engagement.CreditsIssued;
    const engagementdone =
      response?.response.Campaigns.Fields.Engagement.CreditsAvailable;

    if (
      parseInt(publication) > 0 ||
      parseInt(influencer) > 0 ||
      parseInt(engagement) > 0
    ) {
      setPostCount(parseInt(publication));
      setInfluencerCount(parseInt(influencer));
      setEngagementCount(parseInt(engagement));
      setEngagementDone(parseInt(engagementdone));
    }
    if (isNaN(publication) && isNaN(influencer) && isNaN(engagement)) {
      setDisableRow(false);
    } else if (
      parseInt(publication) > 0 &&
      parseInt(influencer) > 0 &&
      parseInt(engagement) > 0
    ) {
      setDisableRow(false);
    } else {
      setDisableRow(true);
    }
  };

  return (
    <Box>
      <DummyCampaign universal={universal} />
      <CampaignPublicationsFilter
        sendInformation={sendInformation}
        changeHeaders={changeHeaders}
        selectedPlatform={selectedPlatforms}
        tableHeaders={tableHeaders}
        allSelectedGroups={selectedGroup}
        filter={filter}
        getSearchValue={getSearchValue}
        selectedStartDate={startDate}
        selectedEndDate={endDate}
        sendSelectedFilters={sendSelectedFilters}
        allAllowedPlatforms={allAllowedPlatforms}
        disableRow={disableRow}
        allSelectedTasks={selectedTasks}
        allSelectedMilestones={selectedMilestones}
        setDataRefreshed={setDataRefreshed}
      />{" "}
      <br />
      <p style={{ textAlign: "right", paddingRight: "10px" }}>
        <a
          className={styles.linkButton}
          onClick={() => setOpenPrivatePublicationGuide(true)}
        >
          No engagement for your post?
        </a>
      </p>
      {/* {(user.membershipId === 12 || user.membershipId === 6) && (
        <Box
          style={{
            display: "flex",
            alignItems: "center", // Align items vertically at the center
            padding: "10px",
            fontSize: "16px",
            background: "#d2d7db",
            marginLeft: "200px",
            marginRight: "300px",
            marginBottom: "10px",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={1} style={{ textAlign: "center", justifyContent: "center", marginTop: "7px" }}>
              <Lightbulb />
            </Grid>
            <Grid item xs={11}>
              {" "}
              <p>
                {" "}
                You can add {postCount} more publications and {influencerCount} influencers under your current plan.{" "}
                {convertToInternationalNumberSystem(engagementDone)}/{convertToInternationalNumberSystem(engagementCount)}{" "}
                Engagement utilized. Upgrade now to add more publications.
              </p>
            </Grid>
          </Grid>
        </Box>
      )} */}
      <PrivatePublication
        openPrivatePublicationGuide={openPrivatePublicationGuide}
        setOpenPrivatePublicationGuide={setOpenPrivatePublicationGuide}
      />
      {loading ? (
        <Loader />
      ) : allAllowedPlatforms?.length !== 0 ? (
        <div className={styles.background}>
          <CampaignPublicationTab
            allSelectedPlatforms={allPlatforms}
            selectedPlatform={selectedPlatforms}
            headers={headers}
            bodyColumns={bodyColumns}
            totalRows={totalRows}
            page={page}
            rows={rows}
            sortOrder={sortOrder}
            sortCol={sortCol}
            mimeType={mimeType}
            fileName={fileName}
            sendInformation={sendInformation}
            handleTabChange={handleTabChange}
            loading={loading}
            dataFound={dataFound}
          />
        </div>
      ) : (
        <>
          <div className={styles.container}>
            <div className={styles.child}>
              {" "}
              No Publications added on this campaign
            </div>
          </div>
        </>
      )}
    </Box>
  );
};

export default CampaignPublicationsSnapshot;
